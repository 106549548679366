<template>
	<cam-box class="roadmap" m="2">
		<h1><i class="lni lni-construction"></i>&nbsp; Ellis Project Roadmap</h1>
		<span>Ellis will continue to change to become more useful over time. Here's what we have in store.</span>

		<br><br>
		<hr>
		<br><br>
		<section>
			<h3 class="em">Bills</h3>
			<ul>
				<li><i class="lni lni-checkbox"/>Search for bills and group bills by interest area.</li>
				<li>Recommend bills that interest you.</li>
			</ul>
			<h3 class="em">Members</h3>
			<ul>
				<li>Favorite and receive updates on your favorite members</li>
				<li>Chart and compare partisanship in congress.</li>

			</ul>
		</section>
	</cam-box>
</template>

<script lang="ts">
export default {
	name: 'roadmap',
}
</script>

<style lang="stylus" scoped>
.roadmap {

}
</style>